<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <h1> Grocery Order details</h1>
    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/Page-header";
    import BaseUrl from "../../../components/constants";
    import ToastConfigAlert from "../../../../ToastConfig";
    import LottieLoader from "../../../components/LottieLoader";
    export default {
        name: "ViewOrderDetail",
        components : {
            Layout,
            PageHeader,
            LottieLoader
        },
        data() {
            return {
                title: "Order details",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "order details",
                        active: true,
                    },
                ],
            }
        }
    }
</script>

<style scoped>

</style>